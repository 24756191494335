import { useEffect, useState } from "react";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";

export const useCheckSubitems = (itemIds: Number[]) => {
  const [subitemsExist, setSubitemsExist] = useState(false);
  const [processSubitems, setProcessSubitems] = useState(false);

  useEffect(() => {
    const checkItems = async (itemIds: Number[]) => {
      try {
        const check = await MondayApiQueryInstance.checkSubitems(itemIds);

        let subitemsExist = false;

        for (const item of check) {
          if (item.subitems.length > 0) {
            subitemsExist = true;
            break;
          }
        }

        setSubitemsExist(subitemsExist);
        setProcessSubitems(subitemsExist);

        return check;
      } catch (error) {
        console.error(error);
      }
    };

    if (itemIds.length !== 0) {
      checkItems(itemIds);
    }
  }, [itemIds]);

  return {
    subitemsExist,
    processSubitems,
    setProcessSubitems,
  };
};
