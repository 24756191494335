import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { DropdownChevronRight } from "monday-ui-react-core/icons";
import { SettingSwitch } from "./SettingSwitch";
import useIndicator from "../../../hooks/api/Settings/useIndicator";
import useSyncDeletion from "../../../hooks/api/Settings/useSyncDeletion";
import { SettingsDrawerPropsInterface } from "./types/settings-drawer-interface";
import ButtonComponent from "../../Button";
import { useState } from "react";

import { Loader } from "monday-ui-react-core";

const SettingsDrawerList = ({
  switchValues,
  setSwitchValues,
  handleCloseDrawer,
  mutate,
  isLoading,
}: SettingsDrawerPropsInterface) => {
  const { updateIndicator } = useIndicator();
  const { updateSyncDeletion } = useSyncDeletion();
  const [retryLoading, setRetryLoading] = useState(false);

  const handleRetry = async () => {
    try {
      setRetryLoading(true);
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setRetryLoading(false);
    }
  };

  return (
    <Box sx={{ width: 350 }}>
      <div onClick={() => handleCloseDrawer(false)} className="p-2 ">
        <div className="cursor-pointer flex items-center justify-between rounded hover:bg-[#6768791a] px-2 py-1">
          <h4 className="font-medium">Settings</h4>
          <DropdownChevronRight />
        </div>
      </div>
      <Divider />

      <div className="flex flex-col gap-3 px-4 py-4">
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.MEDIUM} />
          </div>
        ) : !switchValues ? (
          <>
            {retryLoading ? (
              <>
                <div className="flex items-center justify-center">
                  <Loader
                    color={Loader.colors.PRIMARY}
                    size={Loader.sizes.MEDIUM}
                  />
                </div>
              </>
            ) : (
              <>
                <p>
                  Could not retrieve your account settings, please try again.
                </p>
                <ButtonComponent text={"Retry"} onClick={handleRetry} />
              </>
            )}
          </>
        ) : (
          <>
            <SettingSwitch
              label="Visual indicator"
              initialValue={switchValues?.visual_indicator_setting}
              setNewValue={setSwitchValues}
              settingKey="visual_indicator_setting"
              updateFunction={updateIndicator}
              tooltip="Adds an emoji to the name of all connected items"
            />
            <SettingSwitch
              label="Sync item deletion across boards"
              initialValue={switchValues?.sync_deletion_setting}
              setNewValue={setSwitchValues}
              settingKey="sync_deletion_setting"
              updateFunction={updateSyncDeletion}
              tooltip="When an item is deleted on monday all items connected to it will be deleted"
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default SettingsDrawerList;
