import { useState, useEffect } from "react";
import {
  deleteAllItems,
  deleteItem,
  unlinkItem,
} from "../../utils/requests/itemRequests";
import useTokenHook from "../context/useTokenHook";

const useDeleteItems = (tableData) => {
  const [newData, setNewData] = useState(tableData);
  const { token } = useTokenHook();

  useEffect(() => {
    setNewData(tableData);
  }, [tableData]);

  const deleteAllHook = async (userId, itemIds) => {
    try {
      await deleteAllItems(token, itemIds);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteHook = async (userId, itemId, boardId) => {
    try {
      const response = await deleteItem(token, itemId, boardId);

      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unlinkHook = async (userId, itemId, boardId) => {
    try {
      const response = await unlinkItem(token, itemId, boardId);

      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    deleteAllHook,
    deleteHook,
    unlinkHook,
    newData,
    setNewData,
  };
};

export default useDeleteItems;
