import { useEffect, useState } from "react";
import { MondayApiQueryInstance } from "../services/monday/monday-service-ts";
import { complexityConstants } from "../data/constants/complexityConstants";

// const complexity = 500000000 * 0.7;

// if (complexityUsed > complexity) {
//   //itemId array * 30,000
//   //subitems array * 30,000
//   //createWebhook if has subitems 10 if none 6
// }

// if any of them has subitems greater than 5 we can send batchSize of 1
// if any of them don't have subitems greater than 5 we can send batchSize of 50
// 70% 5M max complexity points

export const useCalcComplexity = (itemIds: number[]) => {
  const [complexityUsed, setComplexityUsed] = useState<number>(0);
  const [batchSize, setBatchSize] = useState<number>(50);
  const [moreThan20, setMoreThan20] = useState<boolean>(false);

  const checkComplexity = async (itemIds: number[]) => {
    try {
      const response = await MondayApiQueryInstance.checkSubitems(itemIds);

      // Base complexity: response length * 30,000
      let totalComplexity =
        response.length * complexityConstants.createComplexity;

      // Add complexity for subitems (subitems.length * 30,000 for each item)
      totalComplexity += response.reduce(
        (acc: number, item: any) =>
          acc + item.subitems.length * complexityConstants.createComplexity,
        0
      );

      // Check if any item has subitems
      const hasSubitems = response.some(
        (item: any) => item.subitems.length > 0
      );

      // Add additional complexity: 10 * 20,000 if there are subitems, else 6 * 20,000
      totalComplexity += hasSubitems
        ? 10 * complexityConstants.webhookComplexity
        : 6 * complexityConstants.webhookComplexity;

      // Check batch size based on subitems
      const hasManySubitems = response.some(
        (item: any) => item.subitems.length > 5
      );

      const hasMoreThan20Subitems = response.some(
        (item: any) => item.subitems.length > 20
      );

      if (hasManySubitems) {
        setBatchSize(1);
      } else {
        setBatchSize(50);
      }

      if (hasMoreThan20Subitems) {
        setMoreThan20(true);
      }

      setComplexityUsed(totalComplexity);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (itemIds.length <= 100) {
      checkComplexity(itemIds);
    }
  }, [itemIds]);

  return {
    complexityUsed,
    batchSize,
    moreThan20,
  };
};
