import { useEffect, useState } from "react";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";

export const useCheckUpdates = (itemIds: Number[]) => {
  const [updatesExist, setUpdatesExist] = useState(false);
  const [processUpdates, setProcessUpdates] = useState(false);

  useEffect(() => {
    const checkItems = async (itemIds: Number[]) => {
      try {
        const check = await MondayApiQueryInstance.checkUpdates(itemIds);

        let updatesExist = false;

        for (const item of check) {
          if (item.updates.length > 0) {
            updatesExist = true;
            break;
          }
        }

        setUpdatesExist(updatesExist);
        setProcessUpdates(updatesExist);

        return check;
      } catch (error) {
        console.error(error);
      }
    };

    if (itemIds.length !== 0) {
      checkItems(itemIds);
    }
  }, [itemIds]);

  return {
    updatesExist,
    processUpdates,
    setProcessUpdates,
  };
};
