// src/slices/listSlice.js

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const listSlice = createSlice({
  name: "list",
  initialState: {
    isLoading: [],
    isSelected: [],
    isDisabled: [],
    isDisableAll: false,
  },
  reducers: {
    setItemLoading: (state, action) => {
      if (!state.isLoading.includes(action.payload)) {
        state.isLoading.push(action.payload);
      }
    },
    removeItemLoading: (state, action) => {
      _.remove(state.isLoading, (item) => item === action.payload);
    },
    setItemSelected: (state, action) => {
      if (!state.isSelected.includes(action.payload)) {
        state.isSelected.push(action.payload);
      }
    },
    removeItemSelected: (state, action) => {
      _.remove(state.isSelected, (item) => item === action.payload);
    },
    setItemDisabled: (state, action) => {
      if (!state.isDisabled.includes(action.payload)) {
        state.isDisabled.push(action.payload);
      }
    },
    removeItemDisabled: (state, action) => {
      _.remove(state.isDisabled, (item) => item === action.payload);
    },

    cleanUpItemDisabled: (state, action) => {
      _.remove(state.isDisabled, (item) => item.includes(action.payload));
    },
    setDisableAll: (state, action) => {
      state.isDisableAll = action.payload;
    },
  },
});

export const {
  setItemLoading,
  removeItemLoading,
  setItemSelected,
  removeItemSelected,
  setItemDisabled,
  removeItemDisabled,
  cleanUpItemDisabled,
  setDisableAll,
} = listSlice.actions;

export default listSlice.reducer;
