import useSWR from "swr";
import useUser from "../../context/userHook";
import { getSettings } from "../../../utils/requests/settingsRequest";
import useTokenHook from "../../context/useTokenHook";

const useGetSettings = () => {
  const { userId, accountId } = useUser();
  const { token } = useTokenHook();

  const { error, data, isLoading, mutate, isValidating } = useSWR(
    token ? `settings:${userId}` : null,
    () => getSettings(token, accountId)
  );

  return {
    isLoading,
    error,
    settingsData: data || null,
    mutate,
    isValidating,
  };
};

export default useGetSettings;
