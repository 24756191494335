import { Fragment, useEffect, useState, useRef } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Board,
  DropdownChevronLeft,
  Warning,
} from "monday-ui-react-core/icons";
import { Checkbox, Search, Tooltip } from "monday-ui-react-core";
import { useBoardsList } from "../../hooks/api/useBoardsList";
import useSubitemContext from "../../hooks/context/subitemContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Spinner from "../Spinner";
import { fetchDataGroupBoards } from "../../services/monday-service";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import { useList } from "../../hooks/componentHooks/listHooks";
import GroupsList, { GroupType } from "./GroupsList";
import useSearchList from "../../hooks/componentHooks/useSearchList";
import { useCheckSubitems } from "../../hooks/api/useCheckSubitems";
import { useCheckUpdates } from "../../hooks/api/useCheckUpdates";
import ButtonComponent from "../Button";

interface BoardsListProps {
  workspaceName: string;
  workspaceId: number | string;
  setActiveMenu: (menu: string) => void;
  calcHeight: (el: any) => void;
  batchSize?: number;
  disableAll?: boolean;
}

export type BoardType = {
  name: string;
  id: string | number;
  type: string;
  synced: boolean;
};

export type BoardGroups = {
  [key: string]: GroupType[];
};

const BoardsList = ({
  workspaceName,
  workspaceId,
  setActiveMenu,
  calcHeight,
  batchSize,
  disableAll,
}: BoardsListProps) => {
  const [search, setSearch] = useState("");
  const [previousHeight, setPreviousHeight] = useState<number | null>(null);
  const secondaryMenuRef = useRef<any>(null);
  const [openBoards, setOpenBoards] = useState<String[]>([]);
  const [boardGroups, setBoardGroups] = useState<BoardGroups>({});
  const [retry, setRetry] = useState<boolean>(false);
  const [reauth, setReauth] = useState<number>(0);
  const { boardsData, isLoading, isValidating, error, muatateBoardsList } =
    useBoardsList(workspaceId as number);
  const { parentBoard } = useSubitemContext();
  const { itemIds } = useBoardAndItems();
  const filteredItems = useSearchList<BoardType>(boardsData, search, "name");

  const { subitemsExist, processSubitems, setProcessSubitems } =
    useCheckSubitems(itemIds);
  const { updatesExist, processUpdates, setProcessUpdates } =
    useCheckUpdates(itemIds);

  useEffect(() => {
    if (!isLoading) {
      calcHeight(secondaryMenuRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const checkHeightChange = () => {
      if (secondaryMenuRef.current) {
        const currentHeight = secondaryMenuRef.current.offsetHeight;

        if (previousHeight !== currentHeight) {
          // Height has changed

          setPreviousHeight(currentHeight);

          // Call calcHeight if needed
          calcHeight(secondaryMenuRef);
        }
      }
    };

    // Initial check on mount
    checkHeightChange();

    // Set up a MutationObserver to listen for DOM changes
    const observer = new MutationObserver(checkHeightChange);
    if (secondaryMenuRef.current) {
      observer.observe(secondaryMenuRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });
    }

    // Clean up on unmount
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousHeight]);

  const { cleanUpDisabled } = useList();

  useEffect(() => {
    if (isValidating) {
      cleanUpDisabled(boardsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardsData]);

  const handleBoardClick = async (boardId: string) => {
    if (!openBoards.includes(boardId)) {
      const result = await fetchDataGroupBoards(boardId);

      setBoardGroups((prevGroups) => ({
        ...prevGroups,
        [boardId]: result,
      }));
    }

    setOpenBoards((prevState) =>
      prevState.includes(boardId)
        ? prevState.filter((id) => id !== boardId)
        : [...prevState, boardId]
    );
  };

  const retryFetch = async () => {
    try {
      setRetry(true);
      setReauth(reauth + 1);
      await muatateBoardsList();
    } catch (error) {
      console.error(error);
    } finally {
      setRetry(false);
    }
  };

  if (isLoading || retry) {
    return (
      <>
        <div className="px-2 pt-[15px]">
          <div className="flex items-center gap-2">
            <Spinner /> Getting your boards
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 px-2 pt-[15px]">
        {reauth >= 1 ? (
          <div className="flex items-center text-center justify-center max-w-[241px]">
            <span>
              If the error persists, try authorizing again using this{" "}
              <a
                className="hover:underline"
                rel="noreferrer"
                href="https://auth.monday.com/oauth2/authorize?client_id=9d9d91f40e511e3954b7d1df7184115e"
                target="_blank"
              >
                link
              </a>
            </span>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center">
              <Warning className="mr-1" />
              <span>An error occurred. Please try again.</span>
            </div>
          </>
        )}

        <div className="flex justify-center">
          <ButtonComponent
            text="Retry"
            className="retry-button"
            onClick={retryFetch}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative secondary-menu-ref" ref={secondaryMenuRef}>
      <>
        <div
          style={{ padding: "15px 8px 12px 8px" }}
          className="bg-[#FFFFFF] sticky top-0 z-[1] border-b border-[#C3C6D4] overflow-x-hidden add-item-dropdown"
        >
          <div
            onClick={() => setActiveMenu("main")}
            className="flex flex-row items-center gap-1 mb-2 cursor-pointer rounded hover:bg-[#6768791a] pr-2 pl-1 py-1"
          >
            <DropdownChevronLeft />
            <h4>{workspaceName}</h4>
          </div>
          <Search
            placeholder="Search Boards"
            onChange={(e) => {
              setSearch(e);
            }}
            size={Search.sizes.SMALL}
            value={search}
          />
          {subitemsExist && (
            <Checkbox
              className="mt-2 "
              label="Include Subitems"
              checked={processSubitems}
              onChange={(e) => {
                setProcessSubitems(e.target.checked);
              }}
            />
          )}
          {updatesExist && (
            <Checkbox
              className="mt-2 "
              label="Include Updates"
              checked={processUpdates}
              onChange={(e) => {
                setProcessUpdates(e.target.checked);
              }}
            />
          )}
        </div>
        {/* Boards*/}
        <List>
          {boardsData.length > 0 &&
            filteredItems.map((board: BoardType) => {
              return (
                <Fragment key={board.id}>
                  <Tooltip
                    content={
                      board.synced || parentBoard === board.id
                        ? "Your item is already shared with this board."
                        : ""
                    }
                    zIndex={1010}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleBoardClick(board.id as string);
                      }}
                      sx={{
                        bgcolor: openBoards.includes(board.id as string)
                          ? "#CCE5FF"
                          : "inherit",
                        borderRadius: "4px", // Other styles here
                      }}
                      disabled={
                        (board.synced ? true : false) ||
                        parentBoard === board.id
                      }
                    >
                      <ListItemIcon
                        sx={{ minWidth: "unset" }}
                        className="!w-auto mr-1"
                      >
                        <Board />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "0.875rem",
                          fontFamily: "Poppins",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        primary={`${board.name}`}
                      />
                      {openBoards.includes(board.id as string) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                  </Tooltip>

                  {/* Groups */}
                  <Collapse
                    in={openBoards.includes(board.id as string)}
                    timeout={100}
                    unmountOnExit
                  >
                    <GroupsList
                      processUpdates={processUpdates}
                      processSubitems={processSubitems}
                      boardId={board.id as string}
                      boardGroups={boardGroups}
                      boardSynced={board.synced}
                      batchSize={batchSize}
                      disableAll={disableAll}
                    />
                  </Collapse>
                </Fragment>
              );
            })}
        </List>
      </>
    </div>
  );
};

export default BoardsList;
