import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import mondaySdk from "monday-sdk-js";
import { useList } from "./componentHooks/listHooks";
import useItemLocations from "./api/useItemLocations";

const monday = mondaySdk();

const SOCKET_BASE_URL =
  process.env.REACT_APP_SOCKET_BASE_URL || "http://localhost:5001";

interface SocketIOMessage {
  message: string;
  action: string;
  userId: string;
  targetGroupId: string;
  targetBoardId: string;
}

export const useErrorWebsocket = (userId: string) => {
  const { removeItemDisabled, removeItemLoading, disableOthers } = useList();
  const { mutateItemLocations } = useItemLocations();
  const [currentError, setCurrentError] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);

  const SOCKET_URL = `${SOCKET_BASE_URL}/?userid=${userId}`;

  useEffect(() => {
    const socketConnection = io(SOCKET_URL);

    setSocket(socketConnection);

    socketConnection.on("connect", () => {
      console.log("connected to socket");
      setIsConnected(true);
    });

    socketConnection.on("error", (data: SocketIOMessage) => {
      if (data.userId === userId) {
        setCurrentError(data.message);

        if (data.action === "add_item") {
          removeItemDisabled(`${data?.targetGroupId}-${data?.targetBoardId}`);
          removeItemLoading(`${data?.targetGroupId}-${data?.targetBoardId}`);
        }

        disableOthers(false);

        // Trigger Monday.com notification

        monday.execute("notice", {
          message: data.message,
          type: "error", // "error" for red notification
          timeout: 10000, // 10 seconds timeout
        });
      }
    });

    socketConnection.on("success", async (data: SocketIOMessage) => {
      if (data.userId === userId) {
        setCurrentError(data.message);

        if (data.action === "add_item") {
          removeItemLoading(`${data?.targetGroupId}-${data?.targetBoardId}`);
        }

        disableOthers(false);

        await mutateItemLocations();

        monday.execute("notice", {
          message: data.message,
          type: "success", // "error" for red notification
          timeout: 10000, // 10 seconds timeout
        });
      }
    });

    socketConnection.on("disconnect", () => {
      setIsConnected(false);
    });

    return () => {
      socketConnection.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SOCKET_URL, userId]);

  return { isConnected, currentError, socket };
};
