import axios from "axios";

const simbApi = axios.create({
  baseURL: `${process.env.REACT_APP_URI}`,
  timeout: 30000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("x-access-token")}`,
  },
});

simbApi.interceptors.request.use((config) => config);

export default simbApi;
